import React, {useRef, useState} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import Helmet from 'react-helmet';
import {Stats} from "@react-three/drei";
import {CameraControls} from "../../react-fiber-three/_camera-controls";
// import {EffectComposer, RenderPass, UnrealBloomPass } from 'three-stdlib';
// extend({EffectComposer, RenderPass, UnrealBloomPass});

function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up reducers for the hovered and active reducers
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (mesh.current.rotation.x += 0.01))
    // Return view, these are regular three.js elements expressed in JSX

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? 1.5 : 1}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>
            <boxGeometry args={[1, 1, 1]}/>
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'}/>
        </mesh>
    )
}

// function CamControl() {
// }
function RainbowBox({...rest}) {
    const mesh = useRef()
    useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y = mesh.current.rotation.z += 0.05))
    return (
        <mesh ref={mesh} {...rest}>
            <boxGeometry attach="geometry"/>
            <meshNormalMaterial attach="material"/>
        </mesh>
    )
}

export default function FiberExample() {
    const parent = useRef();
    const url = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/nerf_baselines/nerf_synthetic/nerf/drums/feat_render/depth/000000.png"

    return (
        <Canvas camera={{position: [10, 10, 10], fov: 50, near: 1, far: 500}} width="100%" height="100%">
            {/*<perspectiveCamera fov={50} position={[2, 2, 2]}/>*/}
            <Helmet>
                <style>{`
                    body, body > div, body > div > div {
                        background: #505767;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        height: 100%;
                    }`}
                </style>
            </Helmet>
            <CameraControls ref={parent}/>
            {/* <Canvas camera={{position: [2, 2, 2], fov: 90, near: 1, far: 15}}>*/}
            {/*<Canvas orthographic camera={{position: [0, 0, 2], left: -2, right: 2, top: 2, bottom: 2, zoom: 30}}>*/}
            <ambientLight/>
            <pointLight position={[10, 10, 10]}/>
            <Box position={[-1.2, 0, 0]}/>
            <RainbowBox position={[1.2, 0, 0]}/>
            {/*<effectComposer multisamping={8} renderIndex={1}>*/}
            {/*    <renderPass attachArray="passes" scene={scene} camera={camera}/>*/}
            {/*    <unrealBloomPass attachArray="passes" args={[aspect, 0.4, 1, 0]}/>*/}
            {/*</effectComposer>*/}
            <Stats/>
        </Canvas>
    )
}