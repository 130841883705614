import React, {forwardRef, useEffect, useRef} from 'react';
import {
    Box3,
    MathUtils,
    Matrix4,
    MOUSE,
    Quaternion,
    Raycaster,
    Sphere,
    Spherical,
    Vector2,
    Vector3,
    Vector4,
} from 'three';
import {extend, useFrame, useThree} from '@react-three/fiber';
import CameraControlsDefault from 'camera-controls';


const subsetOfTHREE = {
    MOUSE: MOUSE,
    Vector2: Vector2,
    Vector3: Vector3,
    Vector4: Vector4,
    Quaternion: Quaternion,
    Matrix4: Matrix4,
    Spherical: Spherical,
    Box3: Box3,
    Sphere: Sphere,
    Raycaster: Raycaster,
    MathUtils: {
        DEG2RAD: MathUtils.DEG2RAD,
        clamp: MathUtils.clamp,
    },
};

CameraControlsDefault.install({THREE: subsetOfTHREE});
extend({CameraControlsDefault});

export const CameraControls = forwardRef((_, ref) => {
    const cameraControls = useRef(null);
    const {camera, gl: renderer} = useThree();
    useFrame((_, delta) => cameraControls.current?.update(delta));
    useEffect(() => () => cameraControls.current?.dispose(), []);
    return (
        <cameraControlsDefault
            ref={mergeRefs(cameraControls, ref)}
            args={[camera, renderer.domElement]}
        />
    );
});

function mergeRefs(...refs) {
    return (instance) => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(instance);
            } else if (ref) {
                ref.current = instance;
            }
        }
    };
}
